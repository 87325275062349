import React from "react"

import Layout from "../components/Layout"

const Lugares = () => {
  return (
    <Layout>
      <div className="container margin-y-lg">
        <section className="text-component">
          <div className="max-width-sm margin-x-auto">
            <h2 className="text-xxl font-heading font-bold">Cómo llegar</h2>
            <p className="text-sm font-bold">El Lugar de La Mancha</p>
            <p className="text-sm">
              Según la tradición cervantina Argamasilla de Alba es “El Lugar de
              La Mancha” de cuyo nombre no quiso acordarse Cervantes en la
              primera parte del Quijote. Está enclavada en la parte más llana de
              la meseta manchega. Por el centro de la villa pasa la Carretera
              Nacional 310, ahora desviada por una variante. De sur a norte
              siempre la cruzó el río Guadiana y el Canal del Gran Prior, y de
              este a oeste la Cañada Real de Cuenca o Vereda Soriana. En su
              término municipal está enclavado el Parque Natural de Las Lagunas
              de Ruidera.
            </p>
          </div>
          <div className="margin-y-md">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3094.9367389887016!2d-3.095647684290207!3d39.13067097953534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd68fd5e912bef13%3A0x6b1a75a9518f817c!2sCasa%20Rural%20Alonso%20Quijano!5e0!3m2!1ses-419!2ses!4v1599085494784!5m2!1ses-419!2ses"
              frameborder="0"
              style={{ height: 600 + "px" }}
              aria-hidden="false"
              className="width-100% height-xxxxl"
              title="Ubicación de Casa Rusal Quijano Alonso"
            ></iframe>
          </div>
          <div className="max-width-sm margin-x-auto">
            <p className="text-sm">
              Su término abarca 40.843 hectáreas, y su población ronda los 7.000
              habitantes. Basa su economía en la agricultura y la ganadería y,
              en menor escala, en la industria y el turismo. Cuenta con un
              embalse, el Pantano de Peñarroya -primero del Guadiana- cuya
              capacidad es de 47,83 hectómetros cúbicos. Dispone de una amplia
              infraestructura hotelera.
            </p>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Lugares
